import React, { useState, useMemo, useRef, useEffect } from "react";
import { useWeb3Modal } from '@web3modal/react'
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
  useDisconnect,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import { CONTRACT_ADDRESS, CONTRACT_ABI, TOKEN_ADDRESS, TOKEN_ABI } from './contants';
import BigNumber from "bignumber.js";
import axios from 'axios';

const Shibai = () => {
  const [showStakeToken, setShowStakeToken] = useState(false);
  const [showUnstakeToken, setShowUnstakeToken] = useState(false);
  let tokenDecimal = 6;

  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { chain: selectedChain, chains,  } = useNetwork()
  const { isLoading: switchingNetwork, switchNetwork } = useSwitchNetwork()
  const { disconnect } = useDisconnect();
  const isValidChain = chains.some(chain => chain?.id === selectedChain?.id);
  const explorer = selectedChain?.blockExplorers?.default;
  const stakeAmountRef = useRef(null);
  const unstakeAmountRef = useRef(null);
  const [exceededApprovedAmount, setExceededApprovedAmount] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [lockedTime, setLockedTime] = useState(0);

  const getReadableAmount = (amount, format = false, decimals = 6) => {
    let bigNum = BigNumber(amount);
    bigNum = isNaN(bigNum) ? 0 : bigNum;
    let realNum = Number(bigNum);
    if(Number(decimals) > 0) realNum = realNum / 10 ** Number(decimals);
    realNum = !Number.isInteger(realNum)? realNum : (format ? realNum?.toLocaleString() || realNum : realNum) || 0;
  
    return realNum;
  };
  
  let { data: readContractData, isError: isContractReadError, error: readContractError, refetch: retryReadContractData } = useContractRead({
    enabled: !!address,
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    chainId: selectedChain?.id,
    functionName: 'getStakingPoolInfo',
    args: [address]
  });

  readContractData = isValidChain ? readContractData : undefined;
  tokenDecimal = readContractData?.decimals || tokenDecimal;

  let { data: readTokenData, isError: isTokenReadError, error: readTokenError, refetch: retryReadTokenData } = useContractRead({
    enabled: !!address,
    address: TOKEN_ADDRESS,
    abi: TOKEN_ABI,
    chainId: selectedChain?.id,
    functionName: 'allowance',
    args: [address, CONTRACT_ADDRESS]
  });

  let { data: readUserData, isSuccess: isReadUserDataSuccess } = useContractRead({
    enabled: !!address,
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    chainId: selectedChain?.id,
    functionName: 'userInfo',
    args: [address]
  });

  const handleOpenWallet = async () => {
    if (!isConnected) return await open({ route: "ConnectWallet" });
  }

  const getReason = (message, fallbackReason = "Something went wrong 😰") => {
    if(!message) return fallbackReason;
    const reasonRegex = /reason:\n([\s\S]*?)\n/;
    const reasonMatch = message?.match?.(reasonRegex);
    const reason = reasonMatch && reasonMatch[1]?.trim();
    return reason || fallbackReason;
  }

  const {data: approveData, error: approveError, isLoading: approving, isSuccess: isApproveSuccess, isError: isApproveError, write: approve } = useContractWrite({
    address: TOKEN_ADDRESS,
    abi: TOKEN_ABI,
    functionName: 'approve',
    chainId: selectedChain?.id
  })

  const {error: approveErrorTx, isLoading: approvingTx, isSuccess: isApproveSuccessTx, isError: isApproveErrorTx} = useWaitForTransaction({
    chainId: selectedChain?.id,
    hash: approveData?.hash,
  })

  const {data: compoundData, error: compoundError, isLoading: compounding, isSuccess: isCompoundSuccess, isError: isCompoundError, write: compound } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'compound',
    chainId: selectedChain?.id
  })

  const {error: compoundErrorTx, isLoading: compoundingTx, isSuccess: isCompoundSuccessTx, isError: isCompoundErrorTx} = useWaitForTransaction({
    chainId: selectedChain?.id,
    hash: compoundData?.hash,
  })

  const {data: stakingData, error: stakingError, isLoading: staking, isSuccess: isStakeSuccess, isError: isStakeError, write: stake } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'deposit',
    chainId: selectedChain?.id
  })

  const {error: stakingErrorTx, isLoading: stakingTx, isSuccess: isStakeSuccessTx, isError: isStakeErrorTx} = useWaitForTransaction({
    chainId: selectedChain?.id,
    hash: stakingData?.hash,
  })

  const {data: unstakingData, error: unstakingError, isLoading: unstaking, isSuccess: isUnstakeSuccess, isError: isUnstakeError, write: unstake } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'withdraw',
    chainId: selectedChain?.id
  })

  const {error: unstakingErrorTx, isLoading: unstakingTx, isSuccess: isUnstakeSuccessTx, isError: isUnstakeErrorTx} = useWaitForTransaction({
    chainId: selectedChain?.id,
    hash: unstakingData?.hash,
  })

  const {data: unstakeAllData, error: unstakingAllError, isLoading: unstakingAll, isSuccess: isUnstakeAllSuccess, isError: isUnstakeAllError, write: unstakeAll } = useContractWrite({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'emergencyWithdraw',
    chainId: selectedChain?.id
  })

  const {error: unstakingAllErrorTx, isLoading: unstakingAllTx, isSuccess: isUnstakeAllSuccessTx, isError: isUnstakeAllErrorTx} = useWaitForTransaction({
    chainId: selectedChain?.id,
    hash: unstakeAllData?.hash,
  })

  const stakeToken = () => {
    const amount = stakeAmountRef?.current?.value * 10**Number(tokenDecimal);
    if(amount > 0) stake({ account: address, args: [amount]});
  }

  const unstakeToken = () => {
    const amount = unstakeAmountRef?.current?.value * 10**Number(tokenDecimal);
    if(amount > 0) unstake({ account: address, args: [amount]});
  }

  const emergencyWithdraw = () => {
    unstakeAll({ account: address, args: []});
  }

  const compoundToken = () => {
    compound({ account: address, args: []});
  }

  const approveToken = () => {
    const amount = stakeAmountRef?.current?.value * 10**Number(tokenDecimal);
    if(amount > 0) approve({ account: address, args: [CONTRACT_ADDRESS, amount] });
  }

  let { buttonText, buttonAction } = useMemo(() => {
    if(isApproveSuccess) retryReadTokenData();

    const stakingAmount = stakeAmountRef?.current?.value ?? 0;
    const allowance = getReadableAmount(readTokenData, false, tokenDecimal);
    const isApproved = allowance >= stakingAmount && allowance > 0;
    let buttonText = !isConnected ? "Connect Wallet" : !isApproved? "Approve $SHIBAI" : "Stake $SHIBAI";
    let buttonAction = !isConnected ? handleOpenWallet : !isApproved? approveToken : stakeToken;

    if(approving) buttonText = "Approving...";
    if(staking) buttonText = "Staking...";

    return { buttonText, buttonAction };
  }, [
    isConnected,
    readContractData,
    readTokenData,
    
    approving,
    isApproveSuccess,
    isApproveError,

    compounding,
    isCompoundSuccess,
    isCompoundError,

    staking,
    isStakeSuccess,
    isStakeError,

    unstaking,
    isUnstakeSuccess,
    isUnstakeError,

    unstakingAll,
    isUnstakeAllSuccess,
    isUnstakeAllError,

    approveErrorTx,
    compoundErrorTx,
    stakingErrorTx,
    unstakingErrorTx,
    unstakingAllErrorTx,

    approvingTx,
    compoundingTx,
    stakingTx,
    unstakingTx,
    unstakingAllTx,

    isApproveSuccessTx,
    isCompoundSuccessTx,
    isStakeSuccessTx,
    isUnstakeSuccessTx,
    isUnstakeAllSuccessTx,

    isApproveErrorTx,
    isCompoundErrorTx,
    isStakeErrorTx,
    isUnstakeErrorTx,
    isUnstakeAllErrorTx,

    exceededApprovedAmount
  ]);

  const stakeAmountChange = event => {
    const allowance = getReadableAmount(readTokenData, false, tokenDecimal);
    const amount = event.target.value * 10**Number(tokenDecimal);
    const isApproved = allowance >= amount && allowance > 0;
    if(!isApproved) return setExceededApprovedAmount(true);
    setExceededApprovedAmount(false);
  };

  useEffect(() => {
    if(isStakeSuccessTx || isCompoundSuccessTx || isUnstakeSuccessTx || isUnstakeAllSuccessTx){
      window?.location?.reload();
    }
  }, [isStakeSuccessTx, isCompoundSuccessTx, isUnstakeSuccessTx, isUnstakeAllSuccessTx,]);

  const hasError = isTokenReadError || isContractReadError || isApproveError || isStakeError || isCompoundError || isUnstakeError || unstakingAllError;
  const errorMessage = hasError ? readTokenError || readContractError || approveError?.message || stakingError?.message || compoundError?.message || unstakingError?.message || unstakingAllError?.message : "";

  const scientificToDecimal = function (num) {
    if(Number.isInteger(num) || typeof num === 'string') return num;

    var nsign = Math.sign(num);
    //remove the sign
    num = Math.abs(num);
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
      var zero = '0',
        parts = String(num).toLowerCase().split('e'), //split into coeff and exponent
        e = parts.pop(), //store the exponential part
        l = Math.abs(e), //get the number of zeros
        sign = e / l,
        coeff_array = parts[0].split('.');
      if (sign === -1) {
        l = l - coeff_array[0].length;
        if (l < 0) {
          num = coeff_array[0].slice(0, l) + '.' + coeff_array[0].slice(l) + (coeff_array.length === 2 ? coeff_array[1] : '');
        }
        else {
          num = zero + '.' + new Array(l + 1).join(zero) + coeff_array.join('');
        }
      }
      else {
        var dec = coeff_array[1];
        if (dec)
          l = l - dec.length;
        if (l < 0) {
          num = coeff_array[0] + dec.slice(0, l) + '.' + dec.slice(l);
        } else {
          num = coeff_array.join('') + new Array(l + 1).join(zero);
        }
      }
    }

    return nsign < 0 ? '-' + num : num;
  };

  const getTokenPrice = async () => {
    try {
      const response = await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&ids=aishiba");
      setTokenPrice(scientificToDecimal(response?.data?.[0]?.current_price));
    } catch (error) {}
  };

  useEffect(() => { getTokenPrice() }, [])

  const getLockedTime = () => {
    const blockTimestamp = Number(readUserData?.[2]);
    if(!blockTimestamp) return 0;

    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(blockTimestamp * 1000); 
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    setLockedTime(formattedDate?.toUpperCase());
  }

  useEffect(() => { getLockedTime() }, [isReadUserDataSuccess])

  const prefillStakeAmount = () => {
    const balance = scientificToDecimal(Number(getReadableAmount(readContractData?.userBalance, true)?.toString()?.replace(/,/g, "")))
    stakeAmountRef.current.value = balance;
  }

  const prefillUnstakeAmount = () => {
    const balance = scientificToDecimal(Number(getReadableAmount(readContractData?.userTotalStakes, true)?.toString()?.replace(/,/g, "")))
    unstakeAmountRef.current.value = balance;
  }

  function fakedAPY() {
    const billion = 9999999999
    const randomNumber = Math.floor(Math.random() * billion) + billion;
    return randomNumber?.toLocaleString();
  }

  let apy = Number(getReadableAmount(readContractData?.apy, true)?.toString()?.replace(/,/g, ""))
  apy = fakedAPY();
  apy = apy?.toLocaleString();

  return (
    <section>
      <div className="max-w-screen-xl mx-auto lg:px-8">
        <div className="relative max-w-xl mx-auto sm:text-center">
          <h2 className="text-slate-50 text-4xl font-semibold sm:text-6xl font-righteous">
            Stake to Earn
          </h2>
        </div>

        <div className="p-8 text-slate-50 text-left max-w-screen-xl mx-auto font-jost mt-[50px] lg:!mt-[80px] lg:w-11/12 rounded-2xl border border-orange-500 bg-gradient-to-b from-orange-950 to-neutral-950">
          <div className="pb-8 border-b border-orange-500 lg:flex lg:justify-between">
            <div className="w-full flex justify-start items-start">
              <p className="text-slate-50 text-3xl">$SHIBAI Stake</p>
            </div>
            <div className="pt-[5px] pr-2 w-full mt-2 lg:mt-0 flex lg:justify-end items-start">
              <p className="text-orange-500 font-bold text-lg pr-2">Price:</p>
              <p className="text-slate-50 font-bold text-lg">${tokenPrice}</p>
            </div>
          </div>

          <div className="py-8 border-b border-orange-500 text-center">
            {!isConnected && <p className="py-4 ">Connect your wallet first</p>}
            {hasError && <p className="py-4 text-[30px] capitalize">{getReason( errorMessage )}</p> }

            <div className="flex flex-col md:flex-row justify-center w-full">
              
              {(isConnected && !isValidChain) && (
                <div className="text-center w-full">
                  <h5 className="font-bold text-[20px] lg:text-[30px] pb-0 font-righteous text-center">Wrong network connected</h5>
                  <button onClick={() => switchNetwork?.(chains?.[0]?.id)} className="p-1 rounded-full !mt-[20px] w-full lg:!w-[30%] outline-none mx-auto px-5 py-3 text-white bg-orange-700 hover:bg-orange-600 active:bg-orange-700 duration-150 shadow-md focus:shadow-none focus:ring-0 ring-offset-2 ring-orange-600 sm:mt-0 sm:ml-3 sm:w-auto">
                    {switchingNetwork ? "Switching..." : `Switch to ${chains?.[0]?.name}`}
                  </button>
                </div>
              )}

              {(isValidChain || !isConnected) && (
                <>
                  <div className="p-1">
                    <button
                      onClick={() => isConnected? setShowStakeToken(!showStakeToken) : handleOpenWallet()}
                      className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                    >
                      {buttonText}
                    </button>
                    {showStakeToken && (
                      <div
                        className="fixed inset-0 flex items-center justify-center z-1000 "
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)"}}
                      >
                        <div className="bg-white rounded-lg p-6 md:w-4/12">
                          <p className="text-orange-500 font-bold text-lg">
                            Stake Your SHIBAI
                          </p>
                          <p className="text-gray-700 pt-3 pb-6">
                            Balance: {scientificToDecimal(getReadableAmount(readContractData?.userBalance, true))} $SHIBAI
                          </p>

                          {hasError && <p className="pb-4 text-[20px] capitalize text-orange-500">{getReason( errorMessage )}</p> }

                          <form
                              onSubmit={(e) => e.preventDefault()}
                              className="max-w-md mx-auto"
                            >
                            <div className="relative">
                              <input
                                ref={stakeAmountRef}
                                onChange={stakeAmountChange}
                                type="number"
                                step="any"
                                placeholder="Amount"
                                className="w-[90%] min-h-[50px] mb-5 pl-5 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-orange-600"
                              />
                              <button
                                onClick={prefillStakeAmount}
                                className="absolute right-[30px] top-[10px] rounded-[5px] w-auto py-[3px] px-[15px] cursor-pointer flex justify-center items-center font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none shadow md:inline"
                              >
                                Max
                              </button>
                            </div>
                          </form>

                          <div className="flex justify-center">
                            <div className="p-1">
                              <button
                                onClick={buttonAction}
                                className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                              >
                                {approvingTx ? "Approving..." : stakingTx ? "Staking..." : buttonText}
                              </button>
                            </div>
                            <div className="p-1">
                              <button
                                className="py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                                onClick={() =>
                                  setShowStakeToken(!showStakeToken)
                                }
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <>
                    {getReadableAmount(readContractData?.userTotalStakes, false) > 0 && (
                      <>
                        <div className="p-1">
                          <button
                            onClick={() => setShowUnstakeToken(!showUnstakeToken)}
                            className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                          >
                            {unstakingTx || unstaking ? "Unstaking..." : "Unstake $SHIBAI"}
                          </button>
                          {showUnstakeToken && (
                            <div
                              className="fixed inset-0 flex items-center justify-center z-1000 "
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)"}}
                            >
                              <div className="bg-white rounded-lg p-6 md:w-6/12">
                                <p className="text-orange-500 font-bold text-lg">
                                  Unstake Your SHIBAI
                                </p>
                                <p className="text-gray-700 pt-3 pb-6">
                                  Staked: {getReadableAmount(readContractData?.userTotalStakes, true)} $SHIBAI
                                </p>

                                {hasError && <p className="pb-4 text-[20px] capitalize text-orange-500">{getReason( errorMessage )}</p> }

                                <form
                                  onSubmit={(e) => e.preventDefault()}
                                  className="max-w-md mx-auto"
                                >
                                  <div className="relative">
                                    <input
                                      ref={unstakeAmountRef}
                                      type="number"
                                      step="any"
                                      placeholder="Amount"
                                      className="w-full min-h-[50px] mb-5 pl-5 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-orange-600"
                                    />
                                    <button
                                      onClick={prefillUnstakeAmount}
                                      className="absolute right-[15px] top-[10px] rounded-[5px] w-auto py-[3px] px-[15px] cursor-pointer flex justify-center items-center font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none shadow md:inline"
                                    >
                                      Max
                                    </button>
                                  </div>
                                </form>

                                <div className="flex flex-col md:flex-row justify-center">
                                  <div className="p-1">
                                    <button
                                      type="button"
                                      onClick={unstakeToken}
                                      className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                                    >
                                      {unstakingTx || unstaking ? "Unstaking..." : "Unstake $SHIBAI"}
                                    </button>
                                  </div>
                                  <div className="p-1">
                                    <button
                                      type="button"
                                      onClick={emergencyWithdraw}
                                      className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                                    >
                                      {unstakingAllTx || unstakingAll ? "Withdrawing..." : "Emergency Withdraw"}
                                    </button>
                                  </div>
                                  <div className="p-1">
                                    <button
                                      className="py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                                      onClick={() =>
                                        setShowUnstakeToken(!showUnstakeToken)
                                      }
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="p-1">
                          <button
                            onClick={compoundToken}
                            className=" py-3 px-8 font-medium text-center text-white bg-orange-600 hover:bg-orange-500 active:bg-orange-700 active:shadow-none rounded-full shadow md:inline"
                          >
                            {compoundingTx || compounding ? "Compounding..." : "Compound $SHIBAI"}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </>
              )}
            </div>
          </div>

          <div className="pt-8 flex flex-col md:flex-row flex-wrap  justify-around">
            <div className="py-4 basis-1/2">
              <p className="text-slate-50">{!isConnected? "Connect your wallet first" : scientificToDecimal(getReadableAmount(readContractData?.userTotalStakes, true))}</p>
              <p className="text-orange-500 font-bold text-lg">
                Staked SHIBAI
              </p>
            </div>
            <div className=" py-4 md:basis-1/2">
              <p className="text-slate-50">{!isConnected? "Connect your wallet first" : scientificToDecimal(getReadableAmount(readContractData?.userPendingRewards, true))}</p>
              <p className="text-orange-500 font-bold text-lg">
                Reward SHIBAI
              </p>
            </div>
            <div className=" py-4 md:basis-1/2">
              <p className="text-slate-50">{!isConnected? "Connect your wallet first" : scientificToDecimal(getReadableAmount(readContractData?.userBalance, true))}</p>
              <p className="text-orange-500 font-bold text-lg">
                Balance SHIBAI
              </p>
            </div>
            <div className=" py-4 md:basis-1/2">
              <p className="text-slate-50">{!isConnected? "Connect your wallet first" : `${apy}%`}</p>
              <p className="text-orange-500 font-bold text-lg">APY</p>
            </div>
            <div className=" py-4 md:basis-1/2">
              <p className="text-slate-50">{lockedTime}</p>
              <p className="text-orange-500 font-bold text-lg">Locked period</p>
            </div>
            <div className=" py-4 md:basis-1/2">
              <p className="text-slate-50">{!isConnected? "Connect your wallet first" : scientificToDecimal(getReadableAmount(readContractData?.totalValueStaked, true))}</p>
              <p className="text-orange-500 font-bold text-lg">Total value locked</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Shibai;