import React from "react";
import Staking from "../Contracts/Staking";

const Earn = () => {
  return (
    <div>
      <Staking />
    </div>
  );
};

export default Earn;
