import React, { Fragment } from "react";
import { Tabs } from "flowbite-react";
import Shibai from "./shibai";
import Shicod from "./shicod";
import ShicodLP from "./lp"; 
import ShibaiNFT from "./nft";

const Staking = () => {
  return (
    <Fragment>
      <section>
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <Tabs.Group
            aria-label="Full width tabs"
            className="font-jost py-10 mx-auto flex justify-center"
          >
            <Tabs.Item title="Stake $SHIBAI">
              <Shibai />
            </Tabs.Item>

            <Tabs.Item title="Stake $SHICOD">
              <Shicod />
            </Tabs.Item>

            <Tabs.Item title="Stake $SHICOD LP">
              <ShicodLP />
            </Tabs.Item>

            <Tabs.Item title="Stake NFT">
              <ShibaiNFT />
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </section>
    </Fragment>
  )
};

export default Staking;
